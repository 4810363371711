import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../../services/auth.service';
import {ConfigBoardsService} from './config-boards.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Rights, User} from '../../../models/user';
import {map} from 'rxjs/operators';
import {JSON} from 'ta-json';
import {NoticeableList, Task} from '../models/task';
import {Board} from '../models/board';
import {Tag} from '../models/tag';
import {Column} from '../models/column';
import {Member} from '../models/member';
import {PlaningBlock} from '../models/planing-block';
import {FiltersAvailable} from '../models/filters-available';
import {ApiService} from '../../../services/api.service';
import {ConfigService} from '../../../services/config.service';
import {UserInfoService} from '../../../services/user-info.service';
import {NotifierService} from 'angular-notifier';
import {HistoryRescheduling} from '../models/history-rescheduling';
import {Tracking} from '../models/tracking';
import {SearchResponse, SearchResult} from '../models/search';
import {ca} from 'date-fns/locale';

@Injectable({
    providedIn: 'root'
})
export class ApiBoardsService extends ApiService {

    /** Текущий пользователь */
    currentUser$ = new BehaviorSubject<User>(null);

    constructor(
        readonly http: HttpClient,
        public configGlobal: ConfigService,
        public userInfo: UserInfoService,
        public auth: AuthService,
        public notifierService: NotifierService,
        public config: ConfigBoardsService) {
        super(http, configGlobal, userInfo, auth, notifierService);
    }


    fetchCurrentUser() {
        this.currentUser$.next(this.auth.auth);
    }

    getNoticeableList(filters) {
        return this.http.get(
            `${this.config.taskManagerTaskUrl}${filters}`,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<NoticeableList[]>(res['data'], NoticeableList))
        );
    }

    getStatistics(filters) {
        return this.http.get(
            `${this.config.taskManagerTaskUrl}${filters}`,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<Task[]>(res['data'], Task))
        );
    }

    getTasks(filters) {
        return this.http.get(
            `${this.config.taskManagerTaskUrl}${filters}`,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                data: JSON.deserialize<Task[]>(res['data'], Task),
                groupsCounters: JSON.deserialize(res['groups_counters'])
            }))
        );
    }

    getColumns(filters) {
        return this.http.get(
            `${this.config.taskManagerTaskUrl}${filters}`,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<Column[]>(res['data'], Column))
        );
    }

    getGantt(filters) {
        return this.http.get(
            `${this.config.taskManagerTaskUrl}${filters}`,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<Task[]>(res['data'], Task))
        );
    }

    getPlaning(filters) {
        return this.http.get(
            `${this.config.taskManagerTaskUrl}${filters}`,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                    data: JSON.deserialize<PlaningBlock[]>(res['data'], PlaningBlock),
                    groupsCounters: JSON.deserialize(res['groups_counters'])
                }))
        );
    }

    getBoards(isSeparate = false) {
        let boardParam = '';
        if (isSeparate) {
            boardParam = '?separate=true';
        }
        return this.http.get(
            `${this.config.taskManagerBoardUrl}${boardParam}`,
            this.auth.authorize(),
        ).pipe(
            map(res => {
                if (isSeparate) {
                    return {
                        privateBoards: (JSON.deserialize<Board[]>(res['data']['private_boards'], Board)).filter(el => !el.is_archive),
                        sharedBoards: (JSON.deserialize<Board[]>(res['data']['shared_boards'], Board)).filter(el => !el.is_archive)
                    };
                }
                return (JSON.deserialize<Board[]>(res['data'], Board)).filter(el => !el.is_archive);
            })
        );
    }

    getAdminBoards() {
        let boardParam = '?full_list=true';

        return this.http.get(
            `${this.config.taskManagerBoardUrl}${boardParam}`,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<Board[]>(res['data'], Board))
        );
    }

    public deleteFile(entity_id: string, fileId: number): Observable<any> {
        return this.http.delete(
            `${this.config.fileStorageURL}files/${entity_id}/${fileId}`,
            this.auth.authorize()
        );
    }

    getTaskById(taskId, boardId?, virtualCondition?) {
        let boardParam = '';
        let virtualIdParam = '';
        if (boardId) {
            boardParam = '?b[]=' + boardId;
        }
        if (virtualCondition && boardId) {
            virtualIdParam = '&v=' + virtualCondition;
        } else if (virtualCondition) {
            virtualIdParam = '?v=' + virtualCondition;
        }
        return this.http.get(
            `${this.config.taskManagerTaskUrl}/${taskId}${boardParam}${virtualIdParam}`,
            this.auth.authorize()
        ).pipe(
            map(res => ({
                        task: JSON.deserialize<Task>(res['data'], Task),
                        rights: JSON.deserialize<Rights>(res['rights'], Rights),
                        buttons: res['buttons'],
                        stat: res['stat'],
                    })
            ));
    }

    getMembers(params = null) {
        const data = params ? params : '';
        return this.http.get(
            `${this.config.taskManagerUrl}user${data}`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Member[]>(res['data'], Member))
        );
    }

    createTask(data, boardId = null) {
        let boardParam = '';
        if (boardId) {
            boardParam = '?b[]=' + boardId;
        }
        return this.http.post(
            `${this.config.taskManagerTaskUrl}/${boardParam}`,
            data,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Task>(res['data'], Task))
        );
    }

    updateTask(data, taskId, boardId = null, repeated = false) {
        /* Можно обновить только часть задачи. Например:
            data = {
                task: {
                    group: {...}
                }
            };
         */

        let boardParam = '';
        if (boardId) {
            boardParam = '?b[]=' + boardId + (repeated ? '&repeated=true': '');
        } else {
            boardParam = (repeated ? '?repeated=true': '');
        }


        return this.http.put(
            `${this.config.taskManagerTaskUrl}/${taskId}${boardParam}`,
            data,
            this.auth.authorize()
        ).pipe(
            map(res => ({
                        task: JSON.deserialize<Task>(res['data'], Task),
                        rights: JSON.deserialize<Rights>(res['rights'], Rights),
                        buttons: res['buttons'],
                    })
            )
        );
    }

    getBoardById(boardId, isAdmin = false) {
        let boardParam = '';
        if (isAdmin) {
            boardParam = '?full_list=true';
        }
        return this.http.get(
            `${this.config.taskManagerBoardUrl}${boardParam}`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Board[]>(res['data'], Board)
            ))
        .pipe(
            map(boards => ({
                        board: boards.find(b => b.id === boardId),
                    })
            ));
    }

    getBoardByIdSingle(boardId) {
        return this.http.get(
            `${this.config.taskManagerBoardUrl}/${boardId}`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Board>(res['data'], Board)));
    }

    createBoard(data) {
        return this.http.post(
            `${this.config.taskManagerBoardUrl}/`,
            data,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Board>(res['data'], Board))
        );
    }

    createColumn(data) {
        return this.http.post(
            `${this.config.taskManagerUrl}column`,
            data,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Column>(res['data'], Column))
        );
    }

    updateColumn(data, columnId) {
        return this.http.put(
            `${this.config.taskManagerUrl}column/${columnId}`,
            data,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Column>(res['data'], Column))
        );
    }

    createPlaningBlock(data) {
        return this.http.post(
            `${this.config.taskManagerUrl}column`,
            data,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<PlaningBlock>(res['data'], PlaningBlock))
        );
    }

    deletePlaningBlockData(columnId) {
        return this.deleteColumn(columnId);
    }

    updatePlaningBlock(data, columnId) {
        return this.updateColumn(data, columnId);
    }

    deleteColumn(columnId) {
        return this.http.delete(
            `${this.config.taskManagerUrl}column/${columnId}`,
            this.auth.authorize()
        );
    }

    updateBoard(data, boardId) {
        return this.http.put(
            `${this.config.taskManagerBoardUrl}/${boardId}`,
            data,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Board>(res['data'], Board))
        );
    }

    public moveBoard(data) {
        return this.http.post(
            `${this.config.taskManagerBoardUrl}`,
            data,
            this.auth.authorize()
        ).pipe(
            map(res => res['sorting'])
        );
    }

    public moveGroups(boardId, data) {
        return this.http.put(
            `${this.config.taskManagerBoardUrl}/${boardId}`,
            data,
            this.auth.authorize()
        ).pipe(
            map(res => res['data'])
        );
    }

    deleteBoard(boardId) {
        return this.http.delete(
            `${this.config.taskManagerBoardUrl}/${boardId}`,
            this.auth.authorize()
        );
    }

    createTag(data) {
        return this.http.post(
            `${this.config.taskManagerTagUrl}/`,
            data,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Tag>(res['data'], Tag))
        );
    }

    updateTag(data, tagId) {
        return this.http.put(
            `${this.config.taskManagerTagUrl}/${tagId}`,
            data,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Tag>(res['data'], Tag))
        );
    }

    getTagById(tagId) {
        return this.http.get(
            `${this.config.taskManagerTagUrl}/${tagId}`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Tag>(res['data'], Tag)
            ));
    }

    getAvailableTags() {
        return this.http.get(
            `${this.config.taskManagerTagUrl}?unique=true&group=true`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Tag>(res['data'], Tag)
            ));
    }

    getAvailableFilters(params) {
        return this.http.get(
            `${this.config.taskManagerUrl}filter${params}`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<FiltersAvailable>(res, FiltersAvailable)
            ));
    }

    deleteTag(tagId) {
        return this.http.delete(
            `${this.config.taskManagerTagUrl}/${tagId}`,
            this.auth.authorize()
        );
    }

    public getFile(entity_id: string, fileId: string, fileName: string): Observable<any> {
        const httpHeaders = this.auth.authorize();
        httpHeaders['responseType'] = 'arraybuffer';
        return this.http.get(
                `${this.config.fileStorageURL}files/${entity_id}/${fileName}`,
            httpHeaders
        );
    }

    // columnId - это ID спринта
    // data - это объект { sorting: [ all task IDs, including current task ], task_id: taskID }
    // в data.sorting можно передать только один taskID, задача упадет в конец списка
    public moveTask(boardId, columnId, data) {
        return this.http.put(
            `${this.config.taskManagerBoardUrl}/${boardId}/sort_column/${columnId}/`,
            data,
            this.auth.authorize()
        ).pipe(
            map(res => res)
        );
    }

    getHistoryById(id: string): Observable<History[]> {
        return this.http.get(
            `${this.config.taskManagerUrl}history/${id}`,
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }

    // Иитория переносов задач и оценок
    getHistoryReschedulingById(id: string) {
        return this.http.get(
            `${this.config.taskManagerUrl}history/${id}?type=rescheduling`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<HistoryRescheduling[]>(res['payload'], HistoryRescheduling))
        );
    }

    setHistoryAcceptance(data) {
        return this.http.post(
            `${this.config.taskManagerUrl}history/`,
            data,
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }

    //отправка созданного файла
    //в базу знаний c конвертацией или без неё
    createFileKB(data) {
        return this.http.post(
            `${this.config.knowledgeBaseFiles}file`,
            data,
            this.auth.authorize()
        ).pipe(
            map(res => res['data'])
        );
    }

    importBoardFromTrello(file: FormData) {
        return this.http.post(
            `${this.config.importerUrl}trello/`,
            file,
            this.auth.authorize()
        ).pipe(
            map(res => res)
        );
    }

    importGetBoardsJira(token: string, email: string, url: string) {
        return this.http.post(
            `${this.config.importerUrl}jira/boards`,
            {api_token: token, email, url},
            this.auth.authorize()
        ).pipe(
            map(res => res)
        );
    }

    importGetBoardsKaiten(token: string, domain: string, inviteUsers = false) {
        return this.http.post(
            `${this.config.importerUrl}kaiten/`,
            {kaiten_company_domain: domain, kaiten_api_key: token, invite_users: inviteUsers},
            this.auth.authorize()
        ).pipe(
            map(res => res)
        );
    }

    importImportBoardsJira(token: string, email: string, url: string, boards: string[], archive: boolean) {
        return this.http.post(
            `${this.config.importerUrl}jira/boards/issues`,
            {api_token: token, email, url, boards_names: boards, archive},
            this.auth.authorize()
        ).pipe(
            map(res => res)
        );
    }

    getBoardStats(boardId) {
        return this.http.get(
            `${this.config.taskManagerUrl}stat/${boardId}`,
            this.auth.authorize()
        )
    }

    addTracking(data) {
        return this.http.post(
            `${this.config.taskManagerUrl}tracking`,
            data,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Tracking>(res['data'], Tracking))
        );
    }

    removeTracking(id){
        return this.http.delete(
            `${this.config.taskManagerUrl}tracking/${id}`,
            this.auth.authorize()
        )
    }

    getTasksByIds(ids: number[]) {
        if (!ids || ids.length < 0) {
            return of([]);
        }
        const idsParams = ids.map(id => `ids[]=${id}`).join('&');

        return this.http.get(
            `${this.config.taskManagerUrl}task?view=calendar&done=true&${idsParams}`,
            this.auth.authorize()
        ).pipe(
            map(res => res['data'] ? res['data'].map(it => JSON.deserialize<Task>(it, Task)) : [])
        );
    }

    exportBoard(boardId) {
        return this.http.get(
            `${this.config.taskManagerUrl}task?role=both&b[]=${boardId}&view=csv`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Task[]>(res['data'], Task))
        );
    }

    exportSprint(boardId, sprintId) {
        return this.http.get(
            `${this.config.taskManagerUrl}task?role=both&b[]=${boardId}&sprint[]=${sprintId}&view=csv`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Task[]>(res['data'], Task))
        );
    }

    searchTasks(query, boardId, addParams?) {
        const params = [`query=${query}`, `board_id=${boardId}`];

        if (addParams) {
            for (let key of Object.keys(addParams)) {
                params.push(key + '=' + addParams[key]);
            }
        }

        const paramsQuery = params.join('&');

        return this.http.get(
            `${this.config.taskManagerUrl}/search?${paramsQuery}`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<SearchResponse>(res, SearchResponse))
        );
    }

    searchTasksMore(query, boardId: number, category: string, offset: number) {
        const params = [
            `query=${query}`,
            `board_id=${boardId}`,
            `category=${category}`,
            `offset=${offset}`,
        ];

        const paramsQuery = params.join('&');

        return this.http.get(
            `${this.config.taskManagerUrl}/search/more?${paramsQuery}`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<SearchResult>(res, SearchResult))
        );
    }
}
