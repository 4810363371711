import {JsonProperty, JsonType} from 'ta-json';
import {Partial} from 'ng-in-viewport/lib/values';

export class TextFilters {
    // search_author: boolean = true,
    // search_responsible: tboolean = rue,
    // search_observer: boolean = true,

    @JsonProperty(`search_description`)
    @JsonType(Boolean)
    search_description?: boolean = true;

    @JsonProperty(`search_dod`)
    @JsonType(Boolean)
    search_dod?: boolean = true;

    @JsonProperty(`search_actual_result:`)
    @JsonType(Boolean)
    search_actual_result?: boolean = true;

    @JsonProperty(`active`)
    @JsonType(Boolean)
    active?: boolean = true;

    @JsonProperty(`archived`)
    @JsonType(Boolean)
    archived?: boolean = false;

    @JsonProperty(`search_sprints`)
    @JsonType(Boolean)
    search_sprints?: boolean = true;
}

export class Filter {
    @JsonProperty(`role`)
    @JsonType(String)
    role = 'both';

    @JsonProperty(`importance`)
    @JsonType(Boolean)
    importance: boolean;

    @JsonProperty(`noticeable`)
    @JsonType(Boolean)
    noticeable: boolean;

    @JsonProperty(`importancePriority`)
    @JsonType(Array)
    importancePriority = [];

    @JsonProperty('excluded_statuses')
    @JsonType(Array)
    excludedStatuses = [];

    @JsonProperty(`done`)
    @JsonType(Boolean)
    done: boolean;

    @JsonProperty(`search`)
    @JsonType(String)
    search: string;

    @JsonProperty(`board`)
    @JsonType(Number)
    board: number;

    @JsonProperty(`sprints`)
    @JsonType(Array)
    sprints = [];

    @JsonProperty(`tags`)
    @JsonType(Array)
    tags = [];

    @JsonProperty('statuses')
    @JsonType(Array)
    statuses = [];

    @JsonProperty('groups')
    @JsonType(Array)
    groups = [];

    @JsonProperty(`performers`)
    @JsonType(Array)
    performers = [];

    @JsonProperty(`observers`)
    @JsonType(Array)
    observers = [];

    @JsonProperty(`authors`)
    @JsonType(Array)
    authors = [];

    @JsonProperty(`dateType`)
    @JsonType(String)
    dateType: string;

    @JsonProperty(`dateFrom`)
    @JsonType(Date)
    dateFrom: Date;

    @JsonProperty(`consider_start_date`)
    @JsonType(Boolean)
    considerStartDate: boolean;

    @JsonProperty(`dateTo`)
    @JsonType(Date)
    dateTo: Date;

    @JsonProperty(`full_hier`)
    @JsonType(Boolean)
    fullHier: boolean;

    @JsonProperty(`matrix`)
    @JsonType(Boolean)
    matrix: boolean;

    @JsonProperty(`isResponsible`)
    @JsonType(Boolean)
    isResponsible: boolean = false;

    @JsonProperty(`textFilters`)
    @JsonType(TextFilters)
    textFilters: TextFilters;

    constructor(
        role: string = '',
        importance: boolean = false,
        done: boolean = false,
        search: string = '',
        board: number = null,
        tags: number[] = [],
        statuses: number[] = [],
        groups: number[] = [],
        sprints: number[] = [],
        performers: number[] = [],
        observers: number[] = [],
        authors: number[] = [],
        dateType: string = '',
        dateFrom: Date = null,
        dateTo: Date = null,
        considerStartDate: boolean = false,
        fullHier: boolean = false,
        importancePriority: number[] = [],
        matrix: boolean = false,
        textFilters: TextFilters = null,
    ) {
        this.role = role;
        this.importance = importance;
        this.importancePriority = importancePriority;
        this.done = done;
        this.search = search;
        this.board = board;
        this.tags = tags;
        this.statuses = statuses;
        this.groups = groups;
        this.sprints = sprints;
        this.observers = observers;
        this.performers = performers;
        this.authors = authors;
        this.dateType = dateType;
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
        this.considerStartDate = considerStartDate;
        this.fullHier = fullHier;
        this.matrix = matrix;
        this.textFilters = textFilters;
    }

    isArchiveOn(filter) {
        return filter.done;
    }

    isOn(filter, view = null) {
        return !!(filter && (
            filter.importance ||
            (filter.importancePriority && filter.importancePriority.length) ||
            filter.dateFrom ||
            filter.dateTo ||
            (filter.tags && filter.tags.length) ||
            (filter.statuses && filter.statuses.length && view !== 'kanban') ||
            (filter.groups && filter.groups.length) ||
            (filter.sprints && filter.sprints.length) ||
            (filter.performers && filter.performers.length) ||
            (filter.observers && filter.observers.length) ||
            (filter.authors && filter.authors.length) ||
            (filter.isResponsible)
        ));
    }
    isHelperOn(filter) {
        return !!(filter && (
            filter.importance ||
            (filter.importancePriority && filter.importancePriority.length) ||
            // filter.dateFrom ||
            // filter.dateTo ||
            (filter.tags && filter.tags.length && !(filter.tags.length === 1 && !filter.tags[0])) ||
            (filter.groups && filter.groups.length && !(filter.groups.length === 1 && !filter.groups[0])) ||
            (filter.sprints && filter.sprints.length) ||
            (filter.performers && filter.performers.length && !(filter.performers.length === 1 && !filter.performers[0])) ||
            (filter.observers && filter.observers.length && !(filter.observers.length === 1 && !filter.observers[0])) ||
            (filter.authors && filter.authors.length)
        ));
    }
}
