<div class="wrapper" [ngClass]="{'_short' : shortView, '_kanban': isKanban}">
    <mat-card class="task"
              *ngIf="task && viewSettingsTask && archiveCondition"
              [id]="task.id"
              [ngClass]="{
               '_opened': task.id === openedId,
               '_dashboard': isDashboard,
               '_new': task.isNew,
               '_expired': task.isTaskExpired,
               '_rejected': task.isTaskRejected && !isTemplate,
               '_archive': task.isTaskArchive,
               '_rejected_archive': task.isTaskRejectedArchive && !isTemplate,
               '_done': task.isTaskDone,
               '_disabled': disabled === true,
               '_membership' : !task.membership(auth.auth.id)
               }">
        <ng-content></ng-content>

            <mat-icon class="icon-another-board"
                      *ngIf="this.tableView === 'hierarchy' && task.onCurrentBoard === false"
                      svgIcon="radius_bookmark-remove"></mat-icon>

            <div class="task__description-toolbar"
                 [ngClass]="{'_no-number': !viewSettingsTask.isNumbersVisible}">

                <div class="task__number"
                     *ngIf="viewSettingsTask.isNumbersVisible && task.type !=='template'"
                     [innerHTML]="task.vid.toString() | highlight : searchTerm"></div>

                <div class="task__favorites"
                     *ngIf="task.flagImportance">

                    <mat-icon svgIcon="radius_star-blue-filled"
                              class="icon"
                              matTooltip="Добавлена в избранное"></mat-icon>
                </div>

                <div class="task__important"
                     *ngIf="!isKanban && task.importance">

                    <mat-icon class="icon"
                              [matTooltip]="importanceText"
                              [svgIcon]="importanceIcon"></mat-icon>
                </div>

                <div class="task__matrix"
                     *ngIf="task.matrixId">

                    <mat-icon svgIcon="radius_matrix" class="icon" matTooltip="Задача находится в Матрице"></mat-icon>

                </div>

                <app-task-manager-status class="task__status"
                                         [ngClass]="{'text-status': viewSettingsTask.isTextStatus  && !isMediaQueryMedium, '_short': !isKanban}"
                                         [disabled]="disabled"
                                         [isText]="viewSettingsTask.isTextStatus && !isMediaQueryMedium"
                                         [data]="task"></app-task-manager-status>
            </div>

            <div class="task__description-main">
                <div class="task__title">
                    <span [innerHTML]="task.title | striphtml | highlight : searchTerm"></span>

                    <div class="match-inside" *ngIf="task.isMatchInside"><mat-icon class="icon">double_arrow</mat-icon></div>
                </div>

                <ul class="task__description-attachments"
                    *ngIf="viewSettingsTask.isBoardsVisible || viewSettingsTask.isTagsVisible|| isSprintVisible">

                    <li class="task__description-tag"
                        *ngIf="isSprintVisible"
                        [ngStyle]="{'background-color' : '#e9eff4'}">
                        {{task.sprint.name}}
                    </li>

                    <ng-container *ngFor="let board of task.boards">
                        <li class="task__description-board"
                            [ngClass]="{'_selected' : isBoardSelected(board)}"
                            *ngIf="viewSettingsTask.isBoardsVisible">
                            <mat-icon *ngIf="board.is_main" class="task__description-board-info"
                                      [ngStyle]="{'color' : getBoardColor(board.color)}">bookmark
                            </mat-icon>
                            <mat-icon *ngIf="!board.is_main" class="task__description-board-info" [ngClass]="{'full-colored': board.is_main}"
                                      [ngStyle]="{'color' : getBoardColor(board.color)}">bookmark_border
                            </mat-icon>
                            <span class="txt" [matTooltip]="getBoardName(board)">{{ getBoardName(board) }}</span>
                        </li>

                        <ng-container *ngIf="viewSettingsTask.isTagsVisible && isBoardSelected(board)">

                            <li class="task__description-tag"
                                *ngFor="let tag of getBoardTags(board)"
                                [ngClass]="{'_selected' : isBoardSelected(board)}"
                                [ngStyle]="{'background-color' : getTagBackground(tag.color)}"
                                [innerHTML]="tag.name | highlight : searchTerm">
                            </li>

                        </ng-container>

                    </ng-container>
                </ul>
            </div>

        <div class="task__toolbar"  [ngClass]="{'unwrapTask' : unwrapTask}">

            <app-small-group class="task__group _list"
                [group]="group"
                [board]="mainBoard"
                             [colorOnly]="breakpoint > 1"
                *ngIf="group && viewSettingsTask.isGroupsVisible"></app-small-group>

            <div class="template-info" *ngIf="task.type == 'template' && task.template && task.template.repeat_every_unit">
                <span class="template-info__title">{{ templateTitle }}</span>
                <span class="template-info__subtitle"
                      [matTooltip]="getTemplateSubtitle(task.template)">{{ templateSubTitle }}</span>
            </div>

            <span class="next-date-template"
                  matTooltip="Следующий повтор"
                  *ngIf="task.type == 'template' && task.template && task.template.next_date && !task.isTaskClose">
                <mat-icon class="icon _template_turn"
                          svgIcon="radius_turn"></mat-icon>
                {{ (task.template.next_date | date: 'dd MMM')?.replace('.','') }}
            </span>

            <div class="task__toolbar-attachments"
                 *ngIf="task.files && task.files.length || task.links && task.links.length">
                <mat-icon class="attach">attach_file</mat-icon>
                {{ task.files.length + task.links.length }}
            </div>

            <app-assessment class="task__assessment _list"
                            *ngIf="task.assessment && viewSettingsTask.isAssessmentVisible"
                            [data]="task.assessment"></app-assessment>

            <mat-icon class="icon _template_turn"
                      svgIcon="radius_turn"
                      matTooltip="Повторяющаяся задача"
                      *ngIf="task.type === 'task' &&  task.template"></mat-icon>

            <div class="task__date _list" *ngIf="isDateStartVisible && isDateVisible && (task.dateStart || task.dateExpiration)">
                {{ task.dateStart ?  (task.dateStart | date: 'd MMM')?.replace('.','') : '...'}}
                - {{ task.dateExpiration ? (task.dateExpiration | date: 'd MMM')?.replace('.','') : '...'}}
            </div>

            <div class="task__date _list" *ngIf="isDateStartVisible && isDateFinishVisible && (task.dateStart || task.dateFinish)">
                {{ task.dateStart ?  (task.dateStart | date: 'd MMM')?.replace('.','') : '...'}}
                - {{ task.dateFinish ? (task.dateFinish | date: 'd MMM')?.replace('.','') : '...'}}
            </div>

            <div class="task__date _list" *ngIf="task.dateStart && isDateStartVisible && !isDateVisible && !isDateFinishVisible">
                {{ (task.dateStart | date: 'd MMM')?.replace('.','') }}
            </div>

            <div class="task__date _list" *ngIf="task.dateExpiration && isDateVisible && !isDateStartVisible">
                {{ (task.dateExpiration | date: 'd MMM')?.replace('.','') }}
            </div>

            <div class="task__date _list" *ngIf="task.dateFinish && isDateFinishVisible && !isDateStartVisible">
                {{ (task.dateFinish | date: 'd MMM')?.replace('.','') }}
            </div>

            <div class="task__toolbar-messages" *ngIf="task.messages" (click)="openChat()">
                <div class="messages">
                    <img src="/assets/icons/chat_mobile.svg" alt="" class="chat-icon" />
                    <span>{{ task.messages }}</span>
                </div>
            </div>

            <span class="planing-task-item__side-users planing-task-item__side-item"
                  *ngIf="viewSettingsTask.isResponsibleVisible && task.activeUsers.length">
                <app-user *ngIf="task.activeUsers[0] && task.activeUsers[0].id === task.responsible?.id"
                          class="user"
                          [data]="task.activeUsers[0]"
                          [responsive]="true"
                          [more]="task.activeUsers.length > 1"></app-user>

                <app-user *ngIf="!(task.activeUsers[0] && task.activeUsers[0].id === task.responsible?.id)"
                          class="user"
                          title="Ответственный не выбран"
                          [data]="null"
                          [responsive]="true"></app-user>
            </span>
            <ng-container *ngIf="viewSettingsTask.isPerformersVisible">
                <ul class="task__toolbar-performers">
                    <ng-container *ngIf="isMediaQueryMedium && task.activeUsers.length >= 1">
                        <li class="task__toolbar-performer" *ngIf="task.activeUsers[0].id !== task.responsible?.id" [matTooltip]="task.activeUsers[0].shortName">
                            <app-user [data]="task.activeUsers[0]" [responsive]="task.activeUsers[0].id === task.responsible?.id"></app-user>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="!isMediaQueryMedium">
                        <ng-container *ngFor="let performer of task.activeUsers; let i = index">
                            <li class="task__toolbar-performer" *ngIf="i < 2 && (performer.id !== task.responsible?.id)" [matTooltip]="performer.shortName">
                                <app-user [data]="performer" [responsive]="performer.id === task.responsible?.id"></app-user>
                            </li>
                        </ng-container>

                        <li class="task__toolbar-performer" *ngIf="task.activeUsers.length > 2">
                            <span class="oversize">+{{task.activeUsers.length - 2}}</span>
                        </li>
                    </ng-container>
                </ul>
            </ng-container>
        </div>
    </mat-card>
</div>

<div class="selected-cnt">
    <ng-content></ng-content>
</div>
